<template>
    <v-form class="wrapperForm">

        <v-row dense>

            <v-col cols="12" sm="12" md="12" class="section-wrapper" v-if="isIncoming">

                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{ $t(registrationInfoTitle) }}</div>                        
                        
                        <v-row no-gutters v-if="notifyCreateDate">
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Дата_формирования") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ notifyCreateDate }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Подписано") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ isNotifySigned ? $t("Да") : $t("Нет") }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters v-if="isNotifyReject">
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Причина") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ notifyRejectReason }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Отправлено") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ notifySendDate ? $t("Да") : $t("Нет") }}
                                </div>
                            </v-col>                            
                        </v-row>

                         <v-row no-gutters v-if="notifySendDate">
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Дата_отправки") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ notifySendDate }}
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
                
            </v-col>

            <v-col cols="12" sm="12" md="12" class="section-wrapper" v-if="isOutgoing">

                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{ $t("Сведения_об_отправке") }}</div>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Отправлено") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ isSended ? $t("Да") : $t("Нет") }}
                                </div>
                            </v-col>                            
                        </v-row>
                        
                        <v-row no-gutters v-if="isSended && sendDate">
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{ $t("Дата_отправки") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData">
                                    {{ $moment(sendDate).format('DD.MM.YYYY') }}
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
                
            </v-col>

            <v-col cols="12" sm="12" md="12" class="section-wrapper" v-if="notifications">
                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{ $t("Уведомления_о_доставке") }}</div>
                        
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="notifications"
                            class="elevation-1 customization-list-table sticky-table"
                            :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                        >
                            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                            <template v-slot:[`item.Correspondent`]="{ item }">
                                <div class="etw-text-container">
                                    <div class="etw-text" v-tooltip.top-center="item.Correspondent">
                                        {{ item.Correspondent }}
                                    </div>
                                </div>
                            </template>

                            <template v-slot:[`item.SignStatus`]="{ item }">
                                <StatusChip :status="getSignStatus(item.SignStatus)" />
                            </template>

                            <template v-slot:[`item.Reject`]="{ item }">
                                <div class="etw-text-container">
                                    <div class="etw-text" v-tooltip.top-center="item.Reject">
                                        {{ item.Reject }}
                                    </div>
                                </div>
                            </template>

                            <template v-slot:[`item.Executer`]="{ item }">
                                <div class="etw-text-container">
                                    <div class="etw-text" v-tooltip.top-center="item.Executer">
                                        {{ item.Executer }}
                                    </div>
                                </div>
                            </template>

                            <template v-slot:[`item.ExecutionText`]="{ item }">
                                <div class="etw-text-container">
                                    <div class="etw-text" v-tooltip.top-center="item.ExecutionText">
                                        {{ item.ExecutionText }}
                                    </div>
                                </div>
                            </template>

                        </v-data-table>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-form>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import StatusChip from '@/components/StatusChip';

export default {
    name: "notifications-tab",
    components: {
        StatusChip
    },
    signStatuses: [
        { id: 0, value: 'None', statusData: { text: "Без_подписи", style: { 'background-color': "#fff", 'color': "#444", 'border': "1px solid #444" } } },
        { id: 1, value: 'Signed', statusData: { text: "Подписано", style: { 'background-color': "#fff", 'color': "#444", 'border': "1px solid #444" } }  },
        { id: 2, value: 'SignValid', statusData: { text: "Подписано", style: { 'background-color': "#43B581" } }  },
        { id: 3, value: 'SignInvalid', statusData: { text: "Подписано", style: { 'background-color': "#F04747" } }  }
    ],
    props: {
        id: {
            type: String,
            default: null
        }
    },
    computed: {
        isOutgoing() {
            return this.$store.getters['actionsource/getDataSourceFullType'] === 'OutgoingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects';
        },
        isIncoming() {
            return this.$store.getters['actionsource/getDataSourceFullType'] === 'IncomingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects';
        },
        registrationInfoTitle() {
            if (this.isNotifyReject)
                return 'Уведомление_об_отклонении';

            return 'Уведомление_о_регистрации';
        }
    },
    data() {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Корреспондент"), value: 'Correspondent', width: '20%' },
                { text: "", value: 'SignStatus', width: '20px', sortable: false },
                { text: i18n.t("Доставлено"), value: 'DeliveryDate', width: '150px' },
                { text: i18n.t("Отказ_в_регистрации"), value: 'Reject', width: '210px' },
                { text: i18n.t("Дата_регистрации"), value: 'RegDate', width: '150px' },
                { text: i18n.t("Рег._номер"), value: 'RegNumber', width: '150px' },
                { text: i18n.t("Срок_исполнения"), value: 'ControlDate', width: '150px' },
                { text: i18n.t("Исполнитель"), value: 'Executer', width: '220px' },
                { text: i18n.t("Телефон_исполнителя"), value: 'ExecuterPhone', width: '180px' },
                { text: i18n.t("Фактический_срок_исполнения"), value: 'ExecutionDate', width: '150px' },
                { text: i18n.t("Результат_исполнения"), value: 'ExecutionText', width: '210px' }
            ],
            notifications: null,
            //#region СВЕДЕНИЯ ОБ ОТПРАВКЕ
            isSended: false,
            sendDate: null,
            //#endregion
            //#region Уведомление о регистрации
            notifyCreateDate: null,
            isNotifyReject: null,
            isNotifySigned: null,
            notifySendDate: null,
            notifyRejectReason: null,
            //#endregion
        }
    },
    async created() {
        await this.update();
    },
    methods: {
        async update() {
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/tabs/notifies/${this.$props.id}`,
                method: 'GET',
                headers: { 'isCommon': this.$store.getters['actionsource/isDataSourceCommon'] },
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.isSended = response?.data?.payload?.sended ?? false;
            this.sendDate = response?.data?.payload?.sendDate;

            this.notifyCreateDate = response?.data?.payload?.outRecord?.NotifyDate ?? null;
            this.isNotifyReject = response?.data?.payload?.outRecord?.IsReject ?? null;
            this.isNotifySigned = response?.data?.payload?.outRecord?.SignStatus > 0 ?? null;
            this.notifySendDate = response?.data?.payload?.outRecord?.SendDate ?? null;
            this.notifyRejectReason = response?.data?.payload?.outRecord?.Reject ?? null;

            this.notifications = response?.data?.payload?.notifications;
            this.loading = false;
        },
        getSignStatus(status) {
            return this.$options.signStatuses.find(x => x.id === status)?.statusData ?? this.$store.getters['references/GetAvaibleStatuses'].unknown;
        }
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    destroyed() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>